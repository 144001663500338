<template>
  <div>
    <modal-detalles :data-convocatoria="dataConvocatoriaDetalles" />
    <b-card
      no-body
      class="mb-0 px-2"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <b-form-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>registros</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="filter"
                size="md"
                type="search"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
                debounce="400"
              />

            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        id="my-table"
        ref="refTablaMisConvocatorias"
        class="position-relative"
        show-empty
        :no-provider-filtering="true"
        head-variant="light"
        hover
        responsive
        :busy.sync="isBusy"
        :items="myProvider"
        :fields="tableColumns"
        :current-page="currentPage"
        :empty-text="'No hay registros para mostrar'"
        :empty-filtered-text="'No hay registros que coincidan con su solicitud.'"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
      >

        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong>Cargando...</strong>
          </div>
        </template>

        <!-- Column: servicios -->
        <template #cell(servicios)="data">
          <div
            v-for="xx in data.item.serviciosx"
            :key="xx.codigo"
            class="text-nowrap"
          >
            <feather-icon
              :icon="resolveServicioIcon(xx.codigo)"
              size="15"
              class="mr-50"
              :class="`text-${resolveServicioVariant(xx.codigo)}`"
            />
            <span
              class="align-text-top text-capitalize"
              :class="`text-${resolveServicioVariant(xx.codigo)}`"
            >{{ xx.nombre }} ({{ xx.n_becas }})</span>
          </div>
        </template>

        <!-- Column: comunicados -->
        <template #cell(comunicados)="data">
          <b-button
            v-for=" (val,index) in data.item.comunicados"
            :key="index"
            :href="val.archivo"
            target="_blank"
            size="sm"
            variant="outline-danger"
            block
          >
            <feather-icon
              icon="FileTextIcon"
              size="10"
            />
            <span style="font-size:9px">
              {{ val.nombre }}
            </span>
            <br>
            <span style="font-size:8px">
              {{ new Intl.DateTimeFormat('es-PE',{month: 'numeric', day:'numeric', year:'numeric', hour:'numeric', minute: 'numeric'}).format(new Date(val.created_at)) }}
            </span>
          </b-button>
        </template>

        <!-- Nombre -->
        <template #cell(nombre)="data">
          <span class="text-primary"><strong>{{ data.item.nombre }}</strong></span>
          <br>
          <b-button
            size="sm"
            block
            variant="outline-primary"
            style="padding:3px"
            @click="actualizarDataConvocatoriaDetalles(data.item)"
          >
            Detalles
          </b-button>
        </template>

        <!-- Column: estado -->
        <template #cell(estado)="data">
          <b-badge
            pill
            :variant="`light-${resolveEstadoVariant(data.item.estado)}`"
            class="text-capitalize"
          >
            {{ data.item.estado }}
          </b-badge>
        </template>

        <!-- Incripcion -->
        <template #cell(inscripcion)="data">
          <span class="text-primary"><strong>Inicio: </strong></span>{{ new Intl.DateTimeFormat('es-PE',{month: 'long', day:'numeric', year:'numeric', hour:'numeric', minute: 'numeric'}).format(new Date(data.item.fecha_inicio)) }} <br>
          <span class="text-primary"><strong>Fin: </strong></span>{{ new Intl.DateTimeFormat('es-PE',{month: 'long', day:'numeric', year:'numeric', hour:'numeric', minute: 'numeric'}).format(new Date(data.item.fecha_fin)) }} <br>
        </template>

        <!-- Column: estado -->
        <template #cell(resultado)="data">

          <div v-if="data.item.estado == 'concluido'">
            <b-button
              variant="outline-info"
              size="sm"
              block
              :href="data.item.archivo_resultado"
              :target="'_blank'"
            >Resultados
              <feather-icon
                icon="ClipboardIcon"
                size="12"
                class="align-middle"
              />
            </b-button>
          </div>

        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando registros del {{ from }} al {{ to }} de un total de {{ totalRows }} registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BSpinner,
  BBadge, BPagination, BButton, BFormSelect,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import misConvocatoriaStoreModule from './misConvocatoriasStoreModule'
import ModalDetalles from './ModalDetalles.vue'

export default {
  components: {
    BFormSelect,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BSpinner,
    BBadge,
    BPagination,
    BButton,
    ModalDetalles,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isBusy: false,
      from: 1,
      to: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      pageOptions: [50, 100, 300, 500],
      sortBy: 'id',
      sortDesc: false,
      // sortDirection: 'desc',
      filter: '',
      filterOn: [],

      dataConvocatoriaDetalles: {},

      tableColumns: [
        { key: 'nombre', label: 'nombre', sortable: true },
        { key: 'servicios', label: 'servicios', sortable: true },

        { key: 'comunicados', label: 'comunicados', sortable: true },
        { key: 'estado', label: 'estado', sortable: true },
        { key: 'resultado', label: 'resultado', sortable: true },
      ],
    }
  },

  methods: {
    actualizarTabla() {
      this.filter = null
      this.$refs.refTablaMisConvocatorias.refresh()
    },
    async myProvider(ctx) {
      const promise = store.dispatch('mis-convocatoria-module/getMisConvocatorias', { queryParams: ctx })

      return promise.then(response => {
        const {
          data, total, from, to,
        } = response.data
        // Pluck the array of items off our axios response
        const items = data
        this.totalRows = total || 0
        // this.from = meta.pagination.count
        this.from = from || 0
        this.to = to || 0

        return items || []
      })
    },
    actualizarDataConvocatoriaDetalles(item) {
      this.dataConvocatoriaDetalles = item
      this.$bvModal.show('modal_detalles_mis_postulaciones')
    },
  },
  setup() {
    const CONVOCATORIA_APP_STORE_MODULE_NAME = 'mis-convocatoria-module'

    // Register module
    if (!store.hasModule(CONVOCATORIA_APP_STORE_MODULE_NAME)) store.registerModule(CONVOCATORIA_APP_STORE_MODULE_NAME, misConvocatoriaStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CONVOCATORIA_APP_STORE_MODULE_NAME)) store.unregisterModule(CONVOCATORIA_APP_STORE_MODULE_NAME)
    })

    const resolveEstadoVariant = status => {
      if (status === 'borrador') return 'secondary'
      if (status === 'vigente') return 'success'
      if (status === 'en curso') return 'warning'
      if (status === 'concluido') return 'danger'
      if (status === 'cancelado') return 'danger'
      return 'primary'
    }
    const resolveServicioVariant = value => {
      if (value === 'BECA-COMEDOR') return 'primary'
      if (value === 'BECA-RESIDENCIA') return 'success'
      if (value === 'BECA-BOLSA') return 'info'
      return 'primary'
    }
    const resolveServicioIcon = value => {
      if (value === 'BECA-COMEDOR') return 'CoffeeIcon'
      if (value === 'BECA-RESIDENCIA') return 'HomeIcon'
      if (value === 'BECA-BOLSA') return 'BriefcaseIcon'

      return 'UserIcon'
    }

    return {
      misConvocatoriaStoreModule,
      avatarText,
      resolveServicioVariant,
      resolveServicioIcon,
      resolveEstadoVariant,

    }
  },
}
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}
</style>
